.wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.main {
  padding: 50px 90px 25px;
  background: white;
  min-height: 100vh;
  max-width: 640px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.secondary {
  background-size: cover;
  background-color: #cacaca;
  min-height: 100vh;
  flex: 1;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.bg {
  width: 100%;
  height: 100vh;
}

.bgColor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #c7c0b8;
  opacity: 0.4;
}
.bgImage {
  width: 100%;
  height: 100vh;
  min-height: 800px;
  object-fit: cover;
}

.tagLine {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  text-align: left;
  transform: translate(-50%, -70%);
  color: white;
  font-size: 70px;
  line-height: 1.1;
  font-weight: 900;
  letter-spacing: 0px;
}

.header {
  padding-bottom: 24px;
  transform: translateX(-10px) translateY(-24px); /* Adjust for internal padding in locale selector */
}

.footer {
  padding-top: 70px;
  text-align: center;
  color: #cacaca;
}

.footerLogo {
  width: 80px;
}

.main :global a {
  color: #333346;
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .main {
    width: 100%;
    padding: 20px;
  }
  .header {
    padding-bottom: 10px;
  }

  .footer {
    padding-top: 20px;
  }
  .tagLine {
    display: none;
  }
}
