.customerForm {
  background: #fafafa;
  margin: 0 auto;
  max-width: 620px;
  padding: 24px;
}

.moreBtn {
  margin-top: 24px;
  color: rgb(51, 51, 70);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0px;
}

.moreBtn:hover {
  color: black;
}
