@import url("https://use.typekit.net/odb7xpx.css");
body {
  margin: 0;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  background: transparent;
  border: 1px #e8e8e8 solid;
  border-width: 1px 0 0 0;
}

.alert-banners div:last-child {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ant-alert-banner {
  z-index: 100;
  font-size: 14px !important;
}

.ant-alert-banner button {
  font-size: 14px !important;
}

.ant-form-item-label {
  line-height: 1 !important;
}

textarea.ant-input {
  padding-top: 10px;
}