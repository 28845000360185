.invoice-calendar {
  display: flex;
  margin-bottom: 20px;
}

.invoice-calendar .DayPicker {
  margin: 0 auto;
}
.invoice-calendar .DayPicker-wrapper {
  outline: none;
}

.invoice-calendar .DayPicker-Month {
  border-collapse: separate;
  border-spacing: 10px;
}

.invoice-calendar .DayPicker-WeekNumber {
  color: rgb(52, 175, 127);
  font-size: 10px;
  padding-right: 16px;
}
.invoice-calendar .DayPicker-Day {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
}
.invoice-calendar .DayPicker-Day--today {
}
.invoice-calendar
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #333345;
}

.invoice-calendar {
  margin-left: -25px;
  margin-right: -25px;
  transform: scale(0.9);
}
