.sidebar__logo {
  color: white;
  margin-top: 0px;
  padding: 44px 0 20px 28px;
  width: 200px;
}
.sidebar__logo img {
  width: 100%;
}
.menu-item__text {
  flex: 1;
  margin-left: 15px !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}
.ant-layout-sider-dark .menu-item__text {
  color: white;
}

.sidebar .ant-menu-dark .menu-item__text {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar .ant-menu-dark .ant-menu-item:hover .menu-item__text {
  color: rgba(255, 255, 255, 0.8);
}

.sidebar .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: transparent;
}
.sidebar .ant-menu.ant-menu-dark .ant-menu-item-selected .menu-item__text {
  font-weight: 700;
  color: white;
}
