.wrapper {
  background: rgb(250, 250, 250);
  border-top: 1px #ededed solid;
  padding: 30px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.wrapper h3 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  padding-bottom: 16px;
}

.infoBox {
  background: white;
  padding: 50px 20px;
  text-align: center;

  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 20px;
}