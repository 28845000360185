.container {
  padding: 25px 25px 0px 25px;
  background: #F8F7FA;
  margin-bottom: 42px;
  min-height: 250px;
}

/* Graphs column */
.graphs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 190px;
  color: #17120f !important;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 600px) {
  .graphs {
    height: 100px;
  }
}

.graphLast a,
.graphUpcoming a {
  color: #17120f !important;
}

.graphLast,
.graphUpcoming {
  padding: 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(50% - 10px);
  height: 100%;
}

.graphUpcoming {
  left: calc(50% + 10px);
}

.graphBarLast,
.graphBarUpcoming {
  width: 100%;
  background: #99D7BF;
  position: absolute;
  bottom: 0;
  transition: height 400ms ease-out;
}

.graphBarUpcoming {
  background: #a3b2d1;
}

.lastAmount,
.upcomingAmount {
  color: black !important;
  position: absolute;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  transition: bottom 400ms ease-out;
}

/* Details column */
.details {
  margin-bottom: 25px;
}

.legendContainer {
  padding: 0 0 20px 0;
}

.title {
  font-weight: 700;
  font-size: 30px;
}

.legendLast,
.legendUpcoming {
  position: relative;
  font-size: 16px;
  padding: 2px 0 2px 30px;
}

.legendLast:before,
.legendUpcoming:before {
  display: block;
  content: " ";
  position: absolute;
  width: 18px;
  height: 18px;
  background: #a3d1b6;
  left: 0;
  top: 5px;
}

.legendUpcoming:before {
  background: #a3b2d1;
}

.finePrint {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
}
