.logged-in-page {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 900px;
  padding-bottom: 60px;
}
.logged-in-page--wide {
  max-width: 1190px;
}

.logged-in-page__header {
  border-bottom: 1px #efefef solid;
  padding: 10px 0 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.logged-in-page__page-title {
  padding: 16px 0;
}

.logged-in-page__page-title h2.ant-typography {
  font-weight: 900;
}

.logged-in-page__menu-toggle {
  width: 32px;
  border-radius: 50%;
  height: 32px;
  background: #333346;
  color: white;
  margin-top: 17px;
  outline: none;
  cursor: pointer;
  display: none;
  padding: 0;
  border: none;
}
.logged-in-page__menu-toggle:active {
  background: #333346;
}

@media screen and (max-width: 600px) {
  .logged-in-page {
    width: 100%;
    padding: 0 20px;
    min-width: 100vw;
  }
  
  .logged-in-page__menu-toggle {
    display: block;
  }

  .logged-in-page__page-title h2 {
    font-size: 24px !important;
    text-align: center;
  }
  .logged-in-page .locale-select {
    padding-top: 0;
  }

  .logged-in-page__locale-switcher {
    margin-right: -14px;
  }
}
