.salary-calculator {
  text-align: center;
  padding: 30px 0 0;
}

.salary-calculator__large-amount {
  font-size: 20px;
}

.salary-calculator .ant-descriptions-row > td {
  display: flex;
  justify-content: space-between;
}

.salary-calculator__more-button {
  margin-top: 24px;
  color: rgb(51, 51, 70);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0px;
}

.salary-calculator__more-button:hover {
  color: black;
}

.salary-calculator .ant-slider-rail,
.salary-calculator .ant-slider-step,
.salary-calculator .ant-slider-track {
  height: 12px;
}

.salary-calculator .ant-slider-track {
  background-color: #282832;
}

.salary-calculator .ant-slider-rail,
.salary-calculator .ant-slider-step,
.salary-calculator .ant-slider-track {
  border-radius: 6px;
}

.salary-calculator .ant-slider-handle {
  width: 28px;
  height: 28px;
  margin-left: -15px;
  margin-top: -8px;
  border-width: 4px;
  border: 6px solid rgb(40, 40, 50);
}

.salary-calculator__details {
  padding-top: 24px;
}
