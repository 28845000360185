@media (min-width: 575.98px) {
  .createdAt {
    float: right;
  }
}

@media (max-width: 575.98px) {
  .createdAt {
    display: block;
    font-style: italic;
  }
}

.description {
  clear: both;
}
