@import url(https://use.typekit.net/odb7xpx.css);
body {
  margin: 0;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  background: transparent;
  border: 1px #e8e8e8 solid;
  border-width: 1px 0 0 0;
}

.alert-banners div:last-child {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ant-alert-banner {
  z-index: 100;
  font-size: 14px !important;
}

.ant-alert-banner button {
  font-size: 14px !important;
}

.ant-form-item-label {
  line-height: 1 !important;
}

textarea.ant-input {
  padding-top: 10px;
}
.locale-select {
  padding-top: 24px;
}
.locale-select .ant-select ,
.locale-select .ant-select-selection {
  border: none;
  outline: none;
  box-shadow: none;
}
.locale-select .ant-select-arrow {
  right: 4px;
}
.sidebar__logo {
  color: white;
  margin-top: 0px;
  padding: 44px 0 20px 28px;
  width: 200px;
}
.sidebar__logo img {
  width: 100%;
}
.menu-item__text {
  flex: 1 1;
  margin-left: 15px !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}
.ant-layout-sider-dark .menu-item__text {
  color: white;
}

.sidebar .ant-menu-dark .menu-item__text {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar .ant-menu-dark .ant-menu-item:hover .menu-item__text {
  color: rgba(255, 255, 255, 0.8);
}

.sidebar .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: transparent;
}
.sidebar .ant-menu.ant-menu-dark .ant-menu-item-selected .menu-item__text {
  font-weight: 700;
  color: white;
}

.auth-layout_wrapper__X3V5S {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.auth-layout_main__3-q_r {
  padding: 50px 90px 25px;
  background: white;
  min-height: 100vh;
  max-width: 640px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.auth-layout_secondary__1ERLf {
  background-size: cover;
  background-color: #cacaca;
  min-height: 100vh;
  flex: 1 1;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.auth-layout_bg__36mXC {
  width: 100%;
  height: 100vh;
}

.auth-layout_bgColor__1GbR6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #c7c0b8;
  opacity: 0.4;
}
.auth-layout_bgImage__blmD3 {
  width: 100%;
  height: 100vh;
  min-height: 800px;
  object-fit: cover;
}

.auth-layout_tagLine__2RHnd {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  text-align: left;
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%);
  color: white;
  font-size: 70px;
  line-height: 1.1;
  font-weight: 900;
  letter-spacing: 0px;
}

.auth-layout_header__32NdI {
  padding-bottom: 24px;
  -webkit-transform: translateX(-10px) translateY(-24px);
          transform: translateX(-10px) translateY(-24px); /* Adjust for internal padding in locale selector */
}

.auth-layout_footer__3ebEY {
  padding-top: 70px;
  text-align: center;
  color: #cacaca;
}

.auth-layout_footerLogo__5u1Wy {
  width: 80px;
}

.auth-layout_main__3-q_r a {
  color: #333346;
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .auth-layout_main__3-q_r {
    width: 100%;
    padding: 20px;
  }
  .auth-layout_header__32NdI {
    padding-bottom: 10px;
  }

  .auth-layout_footer__3ebEY {
    padding-top: 20px;
  }
  .auth-layout_tagLine__2RHnd {
    display: none;
  }
}

.fancy-button {
  background: rgb(255, 255, 255);
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
  border: 2px solid rgb(51, 51, 70);

  color: rgb(51, 51, 70);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  outline: none;

  height: 45px;
  border-radius: 30px;
  line-height: 41px;
  padding: 0 30px;
  margin-bottom: 10px;
}

@media screen and (max-width: 575.98px) {
  .fancy-button {
    width: 100%;
  }
}

.fancy-button:not([disabled]) {
  cursor: pointer;
}

.fancy-button--large {
  height: 60px;
  border-radius: 30px;
  line-height: 58px;
  padding: 0 30px;
}

.fancy-button--cta {
  color: white;
  background: #34af7f;
  box-shadow: none;
  border-color: #34af7f;
}

.fancy-button--secondary {
  box-shadow: none;
  color: white;
  background: rgb(51, 51, 70);
}

.fancy-button--small {
  height: 30px;
  border-radius: 15px;
  line-height: 28px;
  padding: 0 10px;
  font-size: 12px;
  border-width: 1px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.35);
}

.fancy-button--block {
  width: 100%;
}

.fancy-button:hover {
  background: rgb(191, 191, 210);
}
.fancy-button--secondary:hover {
  border-color: rgb(91, 91, 120);
  background: rgb(91, 91, 120);
}

.fancy-button--cta:hover {
  background: #79c8a6;
  border-color: #79c8a6;
}

.fancy-button:active {
  background: rgb(51, 51, 70);
  color: white;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.35);
}
.fancy-button--cta:active {
  background: #34af7f;
  border-color: #34af7f;
  color: rgba(255, 255, 255, 0.5);
}

.fancy-button:disabled,
.fancy-button:disabled:hover {
  color: #999;
  border: 2px solid #999;
  background: rgb(255, 255, 255);
}

.FinePrint_fineprint__1Q8TJ {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
}
.salary-calculator {
  text-align: center;
  padding: 30px 0 0;
}

.salary-calculator__large-amount {
  font-size: 20px;
}

.salary-calculator .ant-descriptions-row > td {
  display: flex;
  justify-content: space-between;
}

.salary-calculator__more-button {
  margin-top: 24px;
  color: rgb(51, 51, 70);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0px;
}

.salary-calculator__more-button:hover {
  color: black;
}

.salary-calculator .ant-slider-rail,
.salary-calculator .ant-slider-step,
.salary-calculator .ant-slider-track {
  height: 12px;
}

.salary-calculator .ant-slider-track {
  background-color: #282832;
}

.salary-calculator .ant-slider-rail,
.salary-calculator .ant-slider-step,
.salary-calculator .ant-slider-track {
  border-radius: 6px;
}

.salary-calculator .ant-slider-handle {
  width: 28px;
  height: 28px;
  margin-left: -15px;
  margin-top: -8px;
  border-width: 4px;
  border: 6px solid rgb(40, 40, 50);
}

.salary-calculator__details {
  padding-top: 24px;
}

@media (min-width: 575.98px) {
  .ActivityListItem_createdAt__12vxZ {
    float: right;
  }
}

@media (max-width: 575.98px) {
  .ActivityListItem_createdAt__12vxZ {
    display: block;
    font-style: italic;
  }
}

.ActivityListItem_description__1hidC {
  clear: both;
}

.MyFinancesWidget_container__1C4Sd {
  padding: 25px 25px 0px 25px;
  background: #F8F7FA;
  margin-bottom: 42px;
  min-height: 250px;
}

/* Graphs column */
.MyFinancesWidget_graphs__2Alno {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 190px;
  color: #17120f !important;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 600px) {
  .MyFinancesWidget_graphs__2Alno {
    height: 100px;
  }
}

.MyFinancesWidget_graphLast__fTjj_ a,
.MyFinancesWidget_graphUpcoming__10E5p a {
  color: #17120f !important;
}

.MyFinancesWidget_graphLast__fTjj_,
.MyFinancesWidget_graphUpcoming__10E5p {
  padding: 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(50% - 10px);
  height: 100%;
}

.MyFinancesWidget_graphUpcoming__10E5p {
  left: calc(50% + 10px);
}

.MyFinancesWidget_graphBarLast__3Zm7e,
.MyFinancesWidget_graphBarUpcoming__C2HVd {
  width: 100%;
  background: #99D7BF;
  position: absolute;
  bottom: 0;
  -webkit-transition: height 400ms ease-out;
  transition: height 400ms ease-out;
}

.MyFinancesWidget_graphBarUpcoming__C2HVd {
  background: #a3b2d1;
}

.MyFinancesWidget_lastAmount__k8Lmw,
.MyFinancesWidget_upcomingAmount__2mftv {
  color: black !important;
  position: absolute;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  -webkit-transition: bottom 400ms ease-out;
  transition: bottom 400ms ease-out;
}

/* Details column */
.MyFinancesWidget_details__Sc6qN {
  margin-bottom: 25px;
}

.MyFinancesWidget_legendContainer__R-tZx {
  padding: 0 0 20px 0;
}

.MyFinancesWidget_title__1U_rD {
  font-weight: 700;
  font-size: 30px;
}

.MyFinancesWidget_legendLast__3l24c,
.MyFinancesWidget_legendUpcoming__1eXl_ {
  position: relative;
  font-size: 16px;
  padding: 2px 0 2px 30px;
}

.MyFinancesWidget_legendLast__3l24c:before,
.MyFinancesWidget_legendUpcoming__1eXl_:before {
  display: block;
  content: " ";
  position: absolute;
  width: 18px;
  height: 18px;
  background: #a3d1b6;
  left: 0;
  top: 5px;
}

.MyFinancesWidget_legendUpcoming__1eXl_:before {
  background: #a3b2d1;
}

.MyFinancesWidget_finePrint__1WP5j {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
}

.logged-in-page {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 900px;
  padding-bottom: 60px;
}
.logged-in-page--wide {
  max-width: 1190px;
}

.logged-in-page__header {
  border-bottom: 1px #efefef solid;
  padding: 10px 0 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.logged-in-page__page-title {
  padding: 16px 0;
}

.logged-in-page__page-title h2.ant-typography {
  font-weight: 900;
}

.logged-in-page__menu-toggle {
  width: 32px;
  border-radius: 50%;
  height: 32px;
  background: #333346;
  color: white;
  margin-top: 17px;
  outline: none;
  cursor: pointer;
  display: none;
  padding: 0;
  border: none;
}
.logged-in-page__menu-toggle:active {
  background: #333346;
}

@media screen and (max-width: 600px) {
  .logged-in-page {
    width: 100%;
    padding: 0 20px;
    min-width: 100vw;
  }
  
  .logged-in-page__menu-toggle {
    display: block;
  }

  .logged-in-page__page-title h2 {
    font-size: 24px !important;
    text-align: center;
  }
  .logged-in-page .locale-select {
    padding-top: 0;
  }

  .logged-in-page__locale-switcher {
    margin-right: -14px;
  }
}

.invoice-table .ant-table-thead {
  font-size: 11px;
}
.invoice-rows .ant-table-thead > tr > th {
  background: transparent;
}
.invoice-rows .ant-table-footer {
  background: transparent;
}
.invoice-summary-amounts-wrapper {
  border-bottom: 1px #e8e8e8 solid;
  margin-bottom: 24px;
  padding: 5px 0 24px;
}

.invoice-summary-amounts {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.invoice-summary-amount__desc {
}

.invoice-summary-amount__value {
  text-align: right;
}

.invoice-summary__show-salary-details {
  text-align: center;
  margin-bottom: 24px;
}

.invoice-summary__show-salary-details .ant-btn-link {
  color: #333346 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.invoice-summary-salary {
  padding: 15px 30px;
  color: white;
  background: #333346;
}

.invoice-summary-salary__row {
  display: flex;
  justify-content: space-between;
  line-height: 50px;
}
.invoice-summary-salary__desc {
  flex: 1 1;
}
.invoice-summary-salary__desc--large {
  font-weight: bold;
}

.invoice-summary-salary__value {
  text-align: right;
}
.invoice-summary-salary__value--large {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 575.98px) {
  .invoice-summary-salary__row {
    line-height: 30px;
  }
}

.invoice-timeline .ant-steps-item-title {
  font-size: 18px;
  font-weight: 700;
}
.invoice-timeline .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.section-header {
  margin: 48px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .section-header--with-border {
    border-bottom: 1px #e8e8e8 solid;

  }

.section-header__help {
}
.CustomerForm_customerForm__1OmcF {
  background: #fafafa;
  margin: 0 auto;
  max-width: 620px;
  padding: 24px;
}

.CustomerForm_moreBtn__2IqrM {
  margin-top: 24px;
  color: rgb(51, 51, 70);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0px;
}

.CustomerForm_moreBtn__2IqrM:hover {
  color: black;
}

@media (min-width: 575.98px) {
  .CustomerSelector_selectOrCreate__3pYoG {
    display: flex;
  }

  .CustomerSelector_select__ADR-J {
    flex: 1 1;
  }

  .CustomerSelector_or__3oTRl {
    padding: 0 10px;
    line-height: 40px;
    color: #a0a0a0;
  }
}

@media (max-width: 575.98px) {
  .CustomerSelector_select__ADR-J {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }

  .CustomerSelector_or__3oTRl {
    display: none;
    clear: both;
  }

  .CustomerSelector_button__3QzC8 {
    width: 100%;
  }
}

.InvoiceAddonTwoColumn_wrapper__3YKSi {
  background: rgb(250, 250, 250);
  border-top: 1px #ededed solid;
  padding: 30px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.InvoiceAddonTwoColumn_wrapper__3YKSi h3 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  padding-bottom: 16px;
}

.InvoiceAddonTwoColumn_infoBox__2BH3e {
  background: white;
  padding: 50px 20px;
  text-align: center;

  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 20px;
}
@media screen and (min-width: 575.98px) {
  .InvoiceForm_cta__3Wdbp {
    margin-left: 24px;
  }
}
.invoice-calendar {
  display: flex;
  margin-bottom: 20px;
}

.invoice-calendar .DayPicker {
  margin: 0 auto;
}
.invoice-calendar .DayPicker-wrapper {
  outline: none;
}

.invoice-calendar .DayPicker-Month {
  border-collapse: separate;
  border-spacing: 10px;
}

.invoice-calendar .DayPicker-WeekNumber {
  color: rgb(52, 175, 127);
  font-size: 10px;
  padding-right: 16px;
}
.invoice-calendar .DayPicker-Day {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
}
.invoice-calendar .DayPicker-Day--today {
}
.invoice-calendar
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #333345;
}

.invoice-calendar {
  margin-left: -25px;
  margin-right: -25px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.invoice-table .ant-table-thead {
  font-size: 11px;
}
.graphql-error {
  top: 0;
  min-height: 100vh;
  position: absolute;
  z-index: 100;
  display: block;
  overflow: auto;
  width: 100%;
  padding: 20px;
  background: #cacaca
}

@media screen and (max-width: 600px) {
  .graphql-error {
    padding: 20px;
  }
  .graphql-error .ant-result-icon {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    margin-bottom: 0;
  }
  .graphql-error .ant-result {
    padding: 0;
    margin: 24px -20px;
  }
  .graphql-error .ant-result-content {
    padding: 0;
  }
}

