.locale-select {
  padding-top: 24px;
}
.locale-select .ant-select ,
.locale-select .ant-select-selection {
  border: none;
  outline: none;
  box-shadow: none;
}
.locale-select .ant-select-arrow {
  right: 4px;
}