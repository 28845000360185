.section-header {
  margin: 48px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .section-header--with-border {
    border-bottom: 1px #e8e8e8 solid;

  }

.section-header__help {
}