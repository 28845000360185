.invoice-summary-amounts-wrapper {
  border-bottom: 1px #e8e8e8 solid;
  margin-bottom: 24px;
  padding: 5px 0 24px;
}

.invoice-summary-amounts {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.invoice-summary-amount__desc {
}

.invoice-summary-amount__value {
  text-align: right;
}

.invoice-summary__show-salary-details {
  text-align: center;
  margin-bottom: 24px;
}

.invoice-summary__show-salary-details .ant-btn-link {
  color: #333346 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.invoice-summary-salary {
  padding: 15px 30px;
  color: white;
  background: #333346;
}

.invoice-summary-salary__row {
  display: flex;
  justify-content: space-between;
  line-height: 50px;
}
.invoice-summary-salary__desc {
  flex: 1;
}
.invoice-summary-salary__desc--large {
  font-weight: bold;
}

.invoice-summary-salary__value {
  text-align: right;
}
.invoice-summary-salary__value--large {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 575.98px) {
  .invoice-summary-salary__row {
    line-height: 30px;
  }
}
