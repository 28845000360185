.graphql-error {
  top: 0;
  min-height: 100vh;
  position: absolute;
  z-index: 100;
  display: block;
  overflow: auto;
  width: 100%;
  padding: 20px;
  background: #cacaca
}

@media screen and (max-width: 600px) {
  .graphql-error {
    padding: 20px;
  }
  .graphql-error .ant-result-icon {
    transform: scale(0.7);
    margin-bottom: 0;
  }
  .graphql-error .ant-result {
    padding: 0;
    margin: 24px -20px;
  }
  .graphql-error .ant-result-content {
    padding: 0;
  }
}
