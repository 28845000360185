@media (min-width: 575.98px) {
  .selectOrCreate {
    display: flex;
  }

  .select {
    flex: 1;
  }

  .or {
    padding: 0 10px;
    line-height: 40px;
    color: #a0a0a0;
  }
}

@media (max-width: 575.98px) {
  .select {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }

  .or {
    display: none;
    clear: both;
  }

  .button {
    width: 100%;
  }
}
