.fancy-button {
  background: rgb(255, 255, 255);
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
  border: 2px solid rgb(51, 51, 70);

  color: rgb(51, 51, 70);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  outline: none;

  height: 45px;
  border-radius: 30px;
  line-height: 41px;
  padding: 0 30px;
  margin-bottom: 10px;
}

@media screen and (max-width: 575.98px) {
  .fancy-button {
    width: 100%;
  }
}

.fancy-button:not([disabled]) {
  cursor: pointer;
}

.fancy-button--large {
  height: 60px;
  border-radius: 30px;
  line-height: 58px;
  padding: 0 30px;
}

.fancy-button--cta {
  color: white;
  background: #34af7f;
  box-shadow: none;
  border-color: #34af7f;
}

.fancy-button--secondary {
  box-shadow: none;
  color: white;
  background: rgb(51, 51, 70);
}

.fancy-button--small {
  height: 30px;
  border-radius: 15px;
  line-height: 28px;
  padding: 0 10px;
  font-size: 12px;
  border-width: 1px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.35);
}

.fancy-button--block {
  width: 100%;
}

.fancy-button:hover {
  background: rgb(191, 191, 210);
}
.fancy-button--secondary:hover {
  border-color: rgb(91, 91, 120);
  background: rgb(91, 91, 120);
}

.fancy-button--cta:hover {
  background: #79c8a6;
  border-color: #79c8a6;
}

.fancy-button:active {
  background: rgb(51, 51, 70);
  color: white;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.35);
}
.fancy-button--cta:active {
  background: #34af7f;
  border-color: #34af7f;
  color: rgba(255, 255, 255, 0.5);
}

.fancy-button:disabled,
.fancy-button:disabled:hover {
  color: #999;
  border: 2px solid #999;
  background: rgb(255, 255, 255);
}
